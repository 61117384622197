@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');
/* @import url('https://maxst.icons8.com/vue-static/landings/line-awesome/font-awesome-line-awesome/css/all.min.css'); */

.App {
  width: 100%;
  height: 100vh;
}
.resellerMain1 {
  display: block;
}

.resellerMain2 {
  display: none;
}

.appMain .appMainInner {
  display: flex;
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}


.Menubar {
  background: #1e1e2d;
  width: 130px;
  height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 98;
}

.appMain .RightMenu {
  padding-left: 295px;
  -webkit-box-flex: 1;
  flex: 1 auto;
  -ms-flex: 1 0 0px;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}





body {
  margin: 0;
  padding: 0;
  background-color: #DDDDDD;
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  font-weight: 400;
}


.MenubarList {
  height: auto;
  padding: 0;
  width: 100%;
}

.MenubarList .row {
  padding: 10px 5px;
  text-align: center;
  margin: 0 0 12px 0;
  display: block;
  color: white;
  align-items: center;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.MenubarList .row:hover {
  cursor: pointer;
  background-color: #1b1b28;
}

.MenubarList #active {
  background-color: #1b1b28;
}

.MenubarList #icon {
  color: #494b74;
}

.MenubarList .row:hover #icon {
  color: #009ef7;
}

.MenubarList .row:hover #title {
  color: #FFFFFF;
}

.MenubarList #active #icon {
  color: #009ef7;
}

.MenubarList #active #title {
  color: #FFFFFF;
}

.MenubarList #title {
  font-size: 12px;
  font-weight: 500;
  margin: 5px 0 0 0;
  color: #9899ac;
}

.MenubarLogo {
  padding: 30px 0;
  text-align: center;
}

.MenubarLogo img {
  filter: brightness(0) invert(1);
  max-height: 25px;
}

.SubSidebar {
  height: 100vh;
  width: 295px;
  background-color: white;
}

.SubSidebar .subSidebarMenu {
  padding: 22px 0 0 0;
}

.SubSidebar .subSidebarMenu ul {
  margin: 0px;
  padding: 15px 0;
  list-style-type: none;
}

.SubSidebar .subSidebarMenu ul li {
  min-height: 44px;
  display: flex;
  padding: 0 25px;
  align-items: center;
  font-weight: 500;
  color: #424242;
}

.SubSidebar .subSidebarMenu ul li:first-child {
  margin: 20px 0 0 0;
  height: 40px;
  min-height: auto;
  color: #666;
  font-weight: 600;
  letter-spacing: 0.3px;
}

.MenubarLogout {
  padding: 16px 0;
  text-align: center;
  margin: 20px 0 0 0;
  cursor: pointer;
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
}

.MenubarLogout svg {
  color: #494b74;
}

.MenubarLogout svg:hover {
  color: #009ef7;
}












/* 1912 */








* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100vh;
}

.main {
  /*display: flex;
  height: 100vh;*/
  width: 100%;
  padding: 0 60px;
}

.main .contentFormOuter {
  width: 100%;
}

.main .contentForm {
  width:680px;
  margin: 25px auto;
  background: #FFFFFF;
  border-radius: 8px;
}

.main .contentForm .formHeading {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ccc;
  min-height: 70px;
  padding: 0 24px;
}

.main .contentForm .formHeading .MuiButtonBase-root.MuiButton-root {
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #FFFFFF;
  text-transform: inherit;
  background: #FFFFFF;
  border: 1px solid #ccc;
  height: 32px;
  color: #2C394B;
}

.main .contentForm .formHeading .MuiButtonBase-root.MuiButton-root:hover {
  background: #F3F6F9;
}

.main .contentForm .formHeading h2 {
  font-family: 'Open Sans', sans-serif;
  font-size: 17px;
  font-weight: 500;
  padding: 0 0 0 16px;
}

.main .contentForm.smallPageWindow .formHeading {
  justify-content: space-between;
}

.main .contentForm.smallPageWindow .formHeading .btnwithText {
  display: flex;
  align-items: center;
}

.main .contentForm.smallPageWindow .formHeading .MuiButtonBase-root.MuiButton-root.black-colorBtn {
  color: #FFFFFF;
  text-transform: inherit;
  background-color: #2C394B;
  border: 0px none;
  height: 36px;
}

.main .contentForm.smallPageWindow .formHeading .MuiButtonBase-root.MuiButton-root.black-colorBtn:hover {
  background: #11161d;
}

.main .contentForm .contentFormBody {
  padding: 24px;
}

.main .contentForm .contentFormBody a {
  font-weight: 500;
  text-decoration: none;
}

.main .contentForm .contentFormBody a:hover {
  color: #12827c;
}

.smlwindowHeight {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
}

.textwithimgBlock {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.textwithimgBlock .textblck {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 66.66667%;
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.textwithimgBlock .textblck p {
  margin-bottom: 16px;
  display: block;
}

.textwithimgBlock .textblck p b {
  font-weight: 600;
}

.textwithimgBlock .imgblck {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.textwithimgBlock .imgblck div {
  text-align: center;
}

.textwithimgBlock .imgblck span {
  display: block;
  color: #F64E60;
  font-weight: 600;
}

.main .contentForm .Form_root .textwithimgBlock .textblck .formButtonMain {
  border: 0px none;
  padding: 0;
  text-align: left;
}

.main .contentForm .Form_root .Form_root_Inner {
  padding: 24px;
}

.main .contentForm .Form_root .Input_root {
  margin: 0 0 24px 0;
}

.main .contentForm .Form_root .Input_root .Input_inputRoot {
  display: grid;
}

.main .contentForm .Form_root .Input_root .Input_inputRoot .Input_input {
  order: 2;
  display: block;
  width: 100%;
  padding: 9px 16px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #424242;
}

.main .contentForm .Form_root .Input_root .Input_inputRoot .Input_label {
  order: 1;
  display: block;
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #424242;
  margin-bottom: 12px;
}

.main .contentForm .Form_root .InputOnlineOrdering label.OnlineOrderingLabel {
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #424242;
  margin-bottom: 12px;
  display: block;
  width: 100%;
}

.main .contentForm .Form_root .InputOnlineOrdering label.OnlineOrderingLabel b {
  display: block;
  font-size: 80%;
  font-weight: 400;
  font-style: italic;
  margin: 4px 0 0 0;
}

.main .contentForm .Form_root .InputOnlineOrdering .Input_root .Input_inputRoot {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}

.main .contentForm .Form_root .InputOnlineOrdering .Input_root .Input_inputRoot .Input_label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  align-items: center;
  background: #F3F6F9;
  margin-right: -1px;
  padding: 9px 16px;
  margin-bottom: 0;
  border: 1px solid #ccc;
  border-radius: 6px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.main .contentForm .Form_root .InputOnlineOrdering .Input_root .Input_inputRoot .Input_input {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}


.main .contentForm .Form_root .formRow {
  display: flex;
  justify-content: flex-start;
  gap: 22px;
}

.main .contentForm .Form_root .formRow .Input_root {
  width: 49%;
}

.main .contentForm .Form_root .InputSelect {
  margin: 0 0 24px 0;
}

.main .contentForm .Form_root .InputSelect label {
  display: block;
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #424242;
  margin-bottom: 12px;
}

.main .contentForm .Form_root .InputSelect select {
  display: block;
  width: 100%;
  padding: 9px 16px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #424242;
}

.main .contentForm .Form_root .formButtonMain {
  padding:24px;
  border-top: 1px solid #ccc;
  text-align: right;
}

.main .contentForm .Form_root .formButtonMain .MuiButtonBase-root.MuiButton-root {
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #FFFFFF;
  text-transform: inherit;
  background-color: #2C394B;
}

.main .contentForm .Form_root .formButtonMain .MuiButtonBase-root.MuiButton-root:hover {
  background: #11161d;
}


.container {
  flex-grow: 1;
  padding: 2em;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.title {
  font-size: 3em;
}

.info {
  font-size: 1.1em;
  letter-spacing: 1px;
  line-height: 1.5;
  margin: 1.5em;
  color: rgb(224, 224, 224);
}

.btn {
  margin: 0 auto;
  border: none;
  outline: none;
  padding: .75em 1em;
  font-size: 1em;
  letter-spacing: 1px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, .4);
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 3px;
  background-color: rgb(134, 49, 0);
  color: #fff;
}

.onlinepaymentInner { padding: 24px;}
.onlinePaymenTitleDiv { margin: 0 0 20px 0; }
.onlinePaymenTitle { color: #2C394B; font-size: 18px; }
.YesNoBtnsDiv { display: flex; margin-bottom: 20px; }
.stripeDiv { display: flex; justify-content: start; align-items: center; }
.clickherestripe { margin-left: 20px; font-weight: bold; }

.sidebar {
  padding-left: 130px;
  flex: 1 1 auto;
  -ms-flex: 1 0 0px;
  flex-direction: column !important;
}

.sidebarOuter {
  display: flex;
}

.sidebarInner {
  width: 295px;
  background-color: #ffffff;
  height: 100%;
  overflow: auto;
  flex-shrink: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 130px;
  z-index: 98;
}

.sidebar .sidebar-item.open .sidebar-content a {
  color: #1b1b28;
}


.sidebar-item {
  padding: 9px 25px;
  display: block;
  transition: background-color .15s;
  border-radius: 5px;
}

.sidebar-item:hover {
  background-color: rgba(255, 255, 255, .1);
}

#menuHeading.sidebar-title {
  min-height: 40px;
  margin: 20px 0 0 0;
  padding: 0 25px;
}

#submenuHeading .sidebar-item {
  padding: 0;
  text-decoration: none;
}

#submenuHeading .sidebar-item a .sidebar-item .sidebar-title {
  padding: 9px 25px;
  color: #2C394B;
  font-weight: 500;
  letter-spacing: inherit;
  cursor: pointer;
}

#submenuHeading .sidebar-item a .sidebar-item .sidebar-content {
  position: relative;
  padding: 0;
}

#submenuHeading .sidebar-item a .sidebar-item .sidebar-content:before {
  content: "";
  height: 100%;
  width: 1px;
  border-left: 1px solid #ebedf3;
  position: absolute;
  left: 39px;
  top: 0;
}

#submenuHeading .sidebar-item a .sidebar-item .sidebar-content a>a.sidebar-item {
  padding: 0 25px 0 54px;
  position: relative;
  min-height: 40px;
  display: flex;
  align-items: center;
}

#submenuHeading .sidebar-item a .sidebar-item .sidebar-content a>a.sidebar-item:before {
  content: "";
  height: 10px;
  width: 10px;
  background: #b5b5c3;
  position: absolute;
  left: 34px;
  top: 15px;
  border-radius: 50%;
}

#submenuHeading .sidebar-item .sidebar-content.nosetup a>a.sidebar-item {
  min-height: 44px;
  display: flex;
  align-items: center;
  padding: 9px 25px;
  font-weight: 500;
  font-size: 13px;
  color: #424242;
}

.sidebar-title {
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.3px;
  color: #666;
  min-height: 44px;
  display: flex;
  align-items: center;
}

.sidebar-title .toggle-btn {
  cursor: pointer;
  transition: transform .3s;
}

.sidebar-item.open>.sidebar-title .toggle-btn {
  transform: rotate(180deg);
}

.sidebar-content {
  padding-top: .25em;
  height: 0;
  overflow: hidden;
}

.sidebar-item.open>.sidebar-content {
  height: auto;
}

.sidebar-item.open>.sidebar-content .sidebar-item {
  padding: 0;
}

.sidebar-item.open>.sidebar-content .sidebar-item .sidebar-title {
  font-weight: 500;
  color: #424242;
}


.sidebar-item.plain {
  color: #fff;
  text-decoration: none;
}

.sidebar-item.plain:hover {
  text-decoration: underline;
}

.sidebar-item.plain i {
  display: inline-block;
  width: 1.7em;
}



.servicePickup {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.servicePickup p {
  font-size: 17px;
  font-weight: 500;
  color: #424242;
}

.MuiButtonBase-root.MuiButton-root.pickupBtns {
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #FFFFFF;
  text-transform: inherit;
  background: #FFFFFF;
  border: 1px solid #ccc;
  height: 40px;
  color: #2C394B;
  min-width: 80px;
  margin: 0 3px;
}

.MuiButtonBase-root.MuiButton-root.pickupBtns.dark-clr {
  color: #FFFFFF;
  background-color: #1BC5BD;
}

.MuiButtonBase-root.MuiButton-root.pickupBtns.dark-clr:hover {
  background: #1BC5BD;
}

.MuiButtonBase-root.MuiButton-root.pickupBtns.white-clr {
  color: #2C394B;
  background-color: #FFFFFF;
}

.MuiButtonBase-root.MuiButton-root.pickupBtns.white-clr:hover {
  background: #F3F6F9;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.login-screen-body {
  background: #F3F6F9;
}

.login-screen-main { display: flex; justify-content: space-between; align-items: center;
  height: 100%;
  min-height: 100vh;
  width: 100%; /*height: 100%;height: 1040px; */ 
    background:#D60000 url('../src/bg-graphic.png') center top no-repeat fixed; 
    background-size:cover; -webkit-background-size:cover; -moz-background-size:cover; -ms-background-size:cover;
}

.login-screen-main .divLeft { width: 50%; padding: 48px;}

.login-screen-main .divLeft {
  -webkit-box-flex: 1;
  flex: 1 auto;
  -ms-flex: 1 0 0px;
  min-width: 0;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  /*background-image: url(images/bg-4.jpg);
  text-align: center;*/
}

.login-screen-main .divLeft .divLefttInner {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.login-screen-main .divLeft .divLefttInner .divLefttInnerMain {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}


.login-screen-main .divRight {
  width: 50%;
  padding: 48px;
}

.login-screen-main .divRight .divRightInner {
  padding: 36px;
  background: #FFFFFF;
  border-radius: 16px;
  width: 85%;
  margin: 0 auto;
}

.login-screen-main .divRight .divRightInner .login-logo-img {
  text-align: center;
  margin-bottom: 36px;
}
.login-screen-main .divRight .divRightInner .login-logo-img img { max-width: 248px; }

.login-screen-main .divRight .divRightInner .login-head {
  margin-bottom: 36px;
  text-align: center;
}

.login-screen-main .divRight .divRightInner .login-head h2 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 8px;
}

.login-screen-main .divRight .divRightInner .login-head p {
  color: #666666;
  font-size: 15px;
  font-weight: 500;
}

.login-screen-main .divRight .divRightInner .Input_root {
  margin: 0 0 16px 0;
}

.login-screen-main .divRight .divRightInner .Input_root .Input_inputRoot {
  display: grid;
}

.login-screen-main .divRight .divRightInner .Input_root .Input_inputRoot .Input_input {
  order: 2;
  display: block;
  width: 100%;
  padding: 8px 16px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #424242;
  height: 42px;
}

.login-screen-main .divRight .divRightInner .Input_root .Input_inputRoot .Input_label {
  order: 1;
  display: block;
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
  font-weight: 500;
  color: #424242;
  margin-bottom: 8px;
}

.login-screen-main .divRight .divRightInner .ph-num-field.phNumber label,
.login-screen-main .divRight .divRightInner .TextArea_label {
    font-size: 15px!important;
    font-weight: 500!important;    
    color: #424242!important;    
  }

.login-screen-main .divRight .divRightInner .TextArea_inputContainer {
  margin: 0 0 16px 0;
}

.login-screen-main .divRight .divRightInner .TextArea_inputContainer .TextArea_innerContainer {
  display: grid;
}

.login-screen-main .divRight .divRightInner .TextArea_inputContainer .TextArea_innerContainer .TextArea_input {
  display: block;
  width: 100%;
  padding: 9px 16px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #424242;
}

.login-screen-main .divRight .divRightInner .TextArea_inputContainer .TextArea_innerContainer .TextArea_label {
  display: block;
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  font-weight: 600;
  color: #060303;
  margin-bottom: 8px;
}

.login-screen-main .divRight .divRightInner .login-btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.login-screen-main .divRight .divRightInner .login-btns .MuiButtonBase-root.MuiButton-root {
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
  font-weight: 500;
  color: #FFFFFF;
  text-transform: inherit;
  background-color: #2C394B;
  padding: 14px 36px;
  line-height: 1.5;
}

.login-screen-main .divRight .divRightInner .login-btns .MuiButtonBase-root.MuiButton-root:hover {
  background: #11161d;
}

.login-screen-main .divRight .divRightInner .login-btns p {
  color: #666666;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}

.login-screen-main .divRight .divRightInner .login-btns p a {
  color: #2C394B;
  text-decoration: none;
}

.login-screen-main .divRight .divRightInner .login-btns p a:hover {
  color: #2C394B;
}

.login-screen-main .login-bottom-menus {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 60px 0 0 0;
}

.login-screen-main .login-bottom-menus p,
.login-screen-main .login-bottom-menus ul li a {
  color: #666666;
  font-weight: 500;
}

.login-screen-main .login-bottom-menus ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.login-screen-main .login-bottom-menus ul li {
  display: inline-flex;
  margin-left: 16px;
}

.login-screen-main .login-bottom-menus ul li a {
  text-decoration: none;
}

.login-screen-main .login-bottom-menus ul li a:hover {
  color: #2C394B;
}

.login-screen-main .remember-forgot-main {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  align-items: center;
}

.login-screen-main .remember-forgot-main label {
  display: flex;
  align-items: center;
  color: #666666;
  font-size: 14px;
  font-weight: 500;
}

.login-screen-main .remember-forgot-main label input[type="checkbox" i] {
  width: 18px;
  height: 18px;
  margin: 0 12px 0 0;
}

.login-screen-main .remember-forgot-main a {
  color: #666666;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
}

.login-screen-main .remember-forgot-main a:hover {
  color: #2C394B;
}

#divSchedule,
#divScheduleDts {
  padding: 0;
  text-align: center;
}

#divSchedule p,
#divScheduleDts p {
  color: #424242;
  font-size: 16px;
  font-weight: 500;
}

#divSchedule h1,
#divScheduleDts h1 {
  margin: 25px 0 20px 0;
  font-size: 32px;
  color: #424242;
  font-weight: 700;
}

#divSchedule h2 {
  font-size: 20px;
  color: #424242;
  font-weight: 700;
  text-align: center;
  margin: 0 auto 20px auto;
}

#divSchedule .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after,
#divSchedule .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  display: none;
}

#divSchedule .react-datepicker {
  width: 550px;
}

/*#divSchedule .react-datepicker__month-container {
  width: 84.5%;
}*/
.white-block-container {
  border: 1px solid rgba(26, 26, 26, 0.1);
  border-radius: 8px;
  box-shadow: 0 1px 3px 0 rgb(0, 0, 0, 0.1);
  background: #ffffff;
  width: 45%;
  margin: 30px auto 0 auto;
  padding: 20px;
  position: relative;
}

.white-block-container .form-block {
  width: 75%;
  margin: 25px auto 0 auto;
  text-align: left;

}

.white-block-container .lineDiv {
  background: #e8e8e8;
  height: 1px;
  margin: 25px 0;
}

.white-block-container .Form_root .Input_root {
  margin: 0 0 28px 0;
}

.white-block-container .Form_root .Input_root .Input_inputRoot {
  display: grid;
}

.white-block-container .Form_root .Input_root .Input_inputRoot .Input_input {
  order: 2;
  display: block;
  width: 100%;
  padding: 9px 16px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #424242;
}

.white-block-container .Form_root .Input_root .Input_inputRoot .Input_label {
  order: 1;
  display: block;
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #424242;
  margin-bottom: 8px;
}

.scheduleDtls {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #424242;
  font-size: 16px;
  margin: 0 0 10px 0;

}

.scheduleDtls div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 15px 0 0;
}

.scheduleDtls div svg {
  color: #424242;
  font-size: 20px;
  margin: 0 8px 0 0;
}

.guests-dtls {

  justify-content: flex-start;
  align-items: center;
  margin: 15px 0 10px 0;
  font-size: 16px;
}

.guests-dtls .img-fluid {
  max-width: 6% !important;
  margin: 0 8px 0 0;
}

.guests-dtls input[type="radio"] {
  width: 20px;
  height: 20px;
  margin: 0 15px 0 0;
}

.guests-dtls svg {
  color: #0099ff;
  font-size: 25px;
  margin: 0 8px 0 0;
}

.white-block-container .btn-row {
  margin: 15px 0 0 0;
}

.white-block-container .btn-row .MuiButtonBase-root.MuiButton-root {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #FFFFFF;
  text-transform: inherit;
  background-color: #0069ff;
  padding: 10px 20px;
  line-height: 1.5;
}

.white-block-container .btn-row .MuiButtonBase-root.MuiButton-root:hover {
  background: #0069ff;
}

.blue-btn {
  background: #0069ff !important;
  border: solid 1px #0069ff !important;
  border-radius: 4px !important;
  color: #ffffff !important;
  text-transform: none !important;
  padding: 3px 15px !important;
}

.white-block-container .TextArea_inputContainer label {
  font-weight: 600;
  margin: 0 0 8px 0;
  display: block;
}

.white-block-container .TextArea_inputContainer {
  margin: 15px 0 0 0;
}

.white-block-container .TextArea_inputContainer textarea {
  order: 2;
  display: block;
  width: 100%;
  padding: 9px 16px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #424242;
}

.white-block-container .back-btn {
  width: 43px;
  height: 43px;
  text-align: center;
  position: absolute;
  left: 20px;
  border: solid 1px #e9e9e9;
  border-radius: 100%;
  min-width: auto;
}

.white-block-container .back-btn svg {
  margin: 0 0 0 8px;
}

.guests-dtls label.note {
  display: block;
  font-size: 12px;
  color: #666666;
  margin: 5px 0 0 60px;
}

.guests-dtls .phoneNum {
  display: block;
  font-size: 12px;
  color: #666666;
  margin: 5px 0 0 60px;
}

.guests-dtls .radio-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.error-wrapper {
  margin: 0 auto 25px auto;
  text-align: center;
}

.error-wrapper label {
  font-size: 14px;
  font-weight: 500;
  color: #e90834;
  background: #fcecef;
  border-radius: 5px;
  border: solid 1px #f5b1bc;
  padding: 5px 10px;
  width: 80%;
  text-align: left;
}

#divSchedule .react-datepicker-popper {
  position: inherit !important;
  transform: none !important;
}

#divSchedule .react-datepicker-popper .react-datepicker {
  border: 0;
  width: 100%;
}

#divSchedule .react-datepicker-popper .react-datepicker__header {
  background-color: transparent;
  border: 0;
}

#divSchedule .react-datepicker-popper .react-datepicker__day,
#divSchedule .react-datepicker-popper .react-datepicker__month-text,
#divSchedule .react-datepicker-popper .react-datepicker__quarter-text,
#divSchedule .react-datepicker-popper .react-datepicker__year-text {
  width: 45px;
  height: 45px;
  font-size: 14px;
  font-weight: 300;
  color: rgba(26, 26, 26, 0.61);
  line-height: 45px;

}

#divSchedule .react-datepicker-popper .react-datepicker__day--selected,
#divSchedule .react-datepicker-popper .react-datepicker__day--in-selecting-range,
#divSchedule .react-datepicker-popper .react-datepicker__day--in-range,
#divSchedule .react-datepicker-popper .react-datepicker__month-text--selected,
#divSchedule .react-datepicker-popper .react-datepicker__month-text--in-selecting-range,
#divSchedule .react-datepicker-popper .react-datepicker__month-text--in-range,
#divSchedule .react-datepicker-popper .react-datepicker__quarter-text--selected,
#divSchedule .react-datepicker-popper .react-datepicker__quarter-text--in-selecting-range,
#divSchedule .react-datepicker-popper .react-datepicker__quarter-text--in-range,
#divSchedule .react-datepicker-popper .react-datepicker__year-text--selected,
#divSchedule .react-datepicker-popper .react-datepicker__year-text--in-selecting-range,
#divSchedule .react-datepicker-popper .react-datepicker__year-text--in-range {
  background-color: rgba(0, 105, 255, 0.2);
  border-radius: 100%;
  width: 45px;
  height: 45px;
  line-height: 45px;
  color: #0060e6;
  font-weight: 700;
  border: 0;
  outline: 0;
}

#divSchedule .react-datepicker-popper .react-datepicker__day-name,
.react-datepicker__day,
#divSchedule .react-datepicker-popper .react-datepicker__time-name {
  width: 45px;
  font-size: 13px;
  color: rgba(26, 26, 26, 0.8);
  font-weight: 600;
  text-transform: uppercase;
}

#divSchedule .react-datepicker-popper .react-datepicker__current-month,
.react-datepicker-time__header,
#divSchedule .react-datepicker-popper .react-datepicker-year-header {
  margin: 0 0 20px 0;
}

#divSchedule .react-datepicker-popper .react-datepicker__navigation--previous {
  left: 7%;
}

#divSchedule .react-datepicker-popper .react-datepicker__navigation--next {
  right: 50%;
}

#divSchedule .react-datepicker-popper .react-datepicker__current-month,
#divSchedule .react-datepicker-popper .react-datepicker-time__header,
#divSchedule .react-datepicker-popper .react-datepicker-year-header {
  font-weight: 500;
}

#divSchedule .react-datepicker-popper .react-datepicker__time-container {
  border: 0;
  width: 200px;
  float: right;
  margin: 0 0 0 15px;
}

#divSchedule .react-datepicker-popper .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 200px;
}

#divSchedule .react-datepicker-popper .react-datepicker__time-list-item {
  border-radius: 4px;
  border: solid 1px #7fb4ff;
  margin: 0 0 8px 0;
  color: rgba(0, 105, 255);
  font-weight: 700;
  height: 40px;
  line-height: 32px;
}

#divSchedule .react-datepicker-popper .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: transparent;
  border: solid 2px #7fb4ff;
}

#divSchedule .time-zone {
  text-align: left;
}

#divSchedule .time-zone h2 {
  font-size: 16px;
  text-align: left;
  margin: 0;
}

#divSchedule .time-zone .select-wrapper {
  display: flex;
  align-items: center;
  border-radius: 6px;
  border: solid 1px #e8e8e8;
  height: 36px;
}

#divSchedule .time-zone .select-wrapper .img-fluid {
  margin: 0 0 0 10px;
  height: 21px;
}

#divSchedule .time-zone .dropdown-block select {
  margin: 0 0 0 10px;
  width: 92%;
  border: none;
  outline: none;
}


#divSchedule .time-zone .select-wrapper, #divSchedule .time-zone .select-wrapper select {
  background-color: #e8e8e8;
}

#divSchedule .time-zone .css-b62m3t-container {
  width: 100%;
}

#divScheduleDts .white-block-container .scheduleDtls .titleIcon {
  display: flex;
  justify-content: center;
  align-items: center;
}

#divSchedule .scheduleDtls .titleIcon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.scheduleCallCalendlyMain {
  width: 100%;
  height: 100vh;
  margin: 10px 0 30px 0;
}

.scheduleCallCalendlyInner {
  margin: 20px 0 30px 0;
  float: right;
}

#divSchedule .time-zone .css-b62m3t-container {

  display: inline-block;
}

#divSchedule .time-zone .select-wrapper:hover {
  background: #e5e5e5;
}

#divSchedule .time-zone .select-wrapper svg {
  width: 20px;
  height: 20px;
  color: #000000;
  margin: 0 0 0 8px;
  display: inline-block;
  vertical-align: middle;
}

#divSchedule .time-zone .select-wrapper .css-13cymwt-control {
  border: 0;
  background: transparent;
  outline: 0;


}

#divSchedule .time-zone .select-wrapper .css-13cymwt-control .css-1fdsijx-ValueContainer {
  outline: 0;
}

#divSchedule .time-zone .select-wrapper .css-1u9des2-indicatorSeparator {
  display: none;
}

.css-b62m3t-container .css-b62m3t-container:focus {
  outline: 0;
}

#divSchedule .time-zone .select-wrapper input:focus {
  border: 0;
  outline: 0;
}

#divSchedule .time-zone .select-wrapper .css-13cymwt-control:focus {
  outline: 0 !important;
  border: 0;
}

#divSchedule .time-zone .css-b62m3t-container {
  border: 0 !important;
  outline: 0 !important;
  box-shadow: none;

}

#divSchedule .react-datepicker-wrapper {
  display: none;
}

#divSchedule .heading-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#divSchedule .next-btn {
  background: #0069ff;
  color: #ffffff;
  font-size: 15px;
  text-transform: none;
  font-weight: 500;
  padding: 5px 8px;
}

#divSchedule .react-datepicker-popper .react-datepicker__day--keyboard-selected,
#divSchedule .react-datepicker-popper .react-datepicker__month-text--keyboard-selected,
#divSchedule .react-datepicker-popper .react-datepicker__quarter-text--keyboard-selected,
#divSchedule .react-datepicker-popper .react-datepicker__year-text--keyboard-selected {
  background-color: rgba(0, 105, 255, 0.2);
  border-radius: 100%;

  color: #0060e6;

}

#divSchedule .react-datepicker-popper .react-datepicker__day:hover,
#divSchedule .react-datepicker-popper .react-datepicker__month-text:hover,
#divSchedule .react-datepicker-popper .react-datepicker__quarter-text:hover,
#divSchedule .react-datepicker-popper .react-datepicker__year-text:hover {
  background-color: rgba(0, 105, 255, 0.2);
  border-radius: 100%;


  color: #0060e6;

}

#divSchedule .time-zone .time-zone-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#divSchedule .time-zone .time-zone-inner .dropdown-block {
  width: 88%;
  margin: 0 10px 0 0;
}
#divSchedule .time-zone .time-zone-inner .dropdown-block .select-wrapper {
  align-items: center;

}
#divSchedule .time-zone .select-wrapper .css-qbdosj-Input {
  position: relative;
}
#divSchedule .time-zone .select-wrapper .css-qbdosj-Input:before {
  position: absolute;
  content: 'icon';
  width: 25px;
  height: 25px;
  content:'';
  background:url('images/globe-icon.png') no-repeat center center;
  background-size: 80%;

}
#divSchedule .time-zone .select-wrapper  .css-1jqq78o-placeholder, #divSchedule .time-zone .select-wrapper  .css-1dimb5e-singleValue {
  padding: 0 0 0 30px;
}


#divSchedule .time-zone .time-zone-inner .dropdown-block .error-block {
  color: #e11601;
  margin: 0 0 0 30px;
}

.form-row {
  position: relative;
}

.form-row .error-msg {
  position: absolute;
  color: #e11601;
  bottom: -20px;
  font-size: 12px;
}

.error-msg {
  color: #e11601;
  font-size: 12px;
}

.msg-error-div {
  margin: 0 0 30px 0;
}

.main .form-overlay .contentForm {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  backdrop-filter: blur(10px);
  pointer-events: none;
}

.form-overlay-none {
  pointer-events: all;
}

.location-block {
  display: flex;
  margin: 0 0 15px 0;
}

.location-block .radio-list {
  padding: 1.4em;
  border: 1px solid #ccc;
  border-radius: 0.42rem;
  margin: 30px;
  width: 50%;
}

.location-block .radio-list:first-child {
  margin: 30px 15px 20px 30px;
}

.location-block .radio-list:nth-child(2) {
  margin: 30px 30px 20px 15px;
}

.radio-list label {
  display: flex;
  align-items: flex-start;
}

.radio-list label>input {
  display: none;
}

.radio-list label>i {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 100%;

  vertical-align: middle;
  border: 2px solid #cccccc;
  transition: 0.25s;
  background: #cccccc;
}

.radio-list label>span {
  display: inline-block;
  width: 85%;
  margin: 0 0 0 4%;


}

.radio-list label>input:checked+i {
  box-shadow: inset 0 0 0 5px #2C394B;
  background: #ffffff;
  border-color: #2C394B;
}

.radio-list label:hover {
  cursor: pointer;
}

.radio-list label>span p {
  font-size: 14px;
  font-weight: 500;
  color: #424242;
}

.radio-list label>span small {
  display: block;
  padding-top: 0.7rem;
  font-size: 12px;
  color: #666;
}

.location-block .error-msg {
  margin: 0 0 20px 30px;
}

.main .contentForm .gridHeading {
  display: flex;
  align-items: center;

  /*min-height: 48px;
  margin: 16px 0 0 0;*/
  justify-content: space-between;
  padding: 16px 16px 0 16px ;
  
}

.main .contentForm .gridHeading .MuiButtonBase-root.MuiButton-root {
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #FFFFFF;
  text-transform: inherit;
  background: #FFFFFF;
  border: 1px solid #ccc;
  height: 32px;
  color: #2C394B;
}

.main .contentForm .gridHeading .MuiButtonBase-root.MuiButton-root:hover {
  background: #F3F6F9;
}

.main .contentForm .gridHeading h2 {
  font-family: 'Open Sans', sans-serif;
  font-size: 17px;
  font-weight: 500;
  padding: 0 0 0 0;
}

.gridPanel {
  padding: 20px;
}

.gridPanel .ag-root-wrapper.ag-layout-normal {
  border: 0;
}

.gridPanel .ag-header {
  background-color: transparent;
  border-bottom: 1px solid #ccc;
}

.gridPanel .ag-ltr .ag-header-viewport .ag-header-cell-resize::after {
  display: none;
}

.gridPanel .ag-header-cell-text {
  color: #424242;
  font-weight: 600;
  font-size: 13px;
}

.gridPanel .ag-cell {
  color: #424242;
  font-weight: 600;
  font-size: 13px;
}

.gridPanel .ag-theme-alpine .ag-row {
  border-bottom: 1px solid #dfdfdf;
}

.gridPanel .ag-center-cols-container {
  width: 100% !important;
}

.gridPanel .ag-theme-alpine .ag-row svg {
  color: #00a0f8;
  margin: 7px 0 0 0;
}


/* Aniket changes starts */
.mainService {
  padding-left: 36px;
  padding-right: 36px;
  padding-bottom: 30px;
}

.daysoptions {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

.daysbox {
  display: flex;
  justify-content: start;
  align-items: center;
}

.dayinput {
  width: 20px;
  height: 20px;
}

.daylabel {
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
}

.delay {
  margin-bottom: 20px;
}

.delayinput {
  margin-top: 10px;
  margin-left: 10px;
  padding-left: 7px;
  height: 30px;
  width: 100px;
  border: 1px solid #9899ac;
  border-radius: 5px;
}

.delayinput:focus {
  outline-width: 0ch;
  outline-color: #9899ac;
}

.paymentmethodslist {
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
}

.choosepayment {
  width: 25%;
  border: 1px solid #9899ac;
  border-radius: 5px;
  /* padding: 10px; */
  margin: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.paymentinput {
  margin-right: 10px;
}

.paymentlabel {
  font-size: 14px;
  font-weight: bold;
}

.payment {
  padding: 10px;
}

.activeServicesBox {
  display: flex;
  justify-content: start;
  align-items: center;
  margin: 24px;
}

.tableRows {
  border: 1px solid grey;
  text-align: left;
  padding: 7px 7px 7px 7px;
}

.scheduletable {
  width: 100%;
  border: 1px solid grey;
  border-collapse: collapse;
  font-family: 'Open Sans', sans-serif;
}

.scheduletable td {
  border: 1px solid #ddd;
  padding: 8px;
}

.scheduletable th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #1e1e2d;
  color: white;
}

.editschedulebtn {
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
  color: #FFFFFF;
  background-color: #2C394B;
  padding-left: 10px;
  padding-right: 10px;
  border: 0px none;
  height: 40px;
  border-radius: 5px;
  font-size: 12px;
}

.editschedulebtn:hover {
  background: #11161d;
}


.closeschedulebtn {
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
  color: #FFFFFF;
  background-color: #ea2525;
  padding-left: 10px;
  padding-right: 10px;
  border: 0px none;
  height: 40px;
  border-radius: 5px;
  font-size: 12px;
}

.closeschedulebtn:hover {
  background: #d80000;
}

.btnminwidth {min-width: 100px;}

.timeTitles {
  font-weight: bold;
  font-size: 14px;
  color: black;
}

.timepicker {
  border: 1px solid #ccc;
}

.openinghrsBox {  
  padding: 24px;  
}

.openDaysBox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
}
.openinghrsBox .openDaysBox { flex-wrap: wrap; justify-content: space-between; }
.openinghrsBox .openDaysBox label { width: 30%; }
.copymonday {
  margin-bottom: 20px;
}

.copyMondaySpan {
  display: flex;
  align-items: center;
}

.chooseTimeBox {
  display: flex;
  gap: 50px;
  margin-bottom: 20px;
  padding-left: 10px;
}

.chooseTimeBox div{ width: 50%;}
.chooseTimeBox .timepicker { width: 100%; }

.daysDropdown {
  padding: 7px;
  border-radius: 5px;
  border-color: #ccc;
}

.openingHoursBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.main .contentForm .openingHoursBox h3 { margin: 0;}

.setdifferentTime .diffhrsBox { padding: 8px 0;}

.datePickerBox {
  display: flex;
  justify-content: start;
  align-items: center;
  max-height: 100px;
}

.holidayTitle {
  font-weight: 600;
  font-size: 16px;
}

.holidayTable {
  border-collapse: collapse;
  width: 100%;
  height: fit-content;
  margin-top: 10px;
}

.dateTd {
  border: 1px solid #DDDDDD;
  text-align: left;
  padding: 7px;
}

.deleteTd {
  border: 1px solid #DDDDDD;
  text-align: center;
  padding: 7px;
}

.diffhrsBox {
  display: flex;
  justify-content: start;
  align-items: center;
}

.savebtn {
  padding: 10px;
  border-radius: 5px;
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #FFFFFF;
  background-color: #2C394B;
  border: none;
}

.savebtn:hover {
  background-color: #11161d;
}

.savebtn1 {
  width: 80px;
  padding: 10px;
  border-radius: 5px;
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #FFFFFF;
  background-color: #2C394B;
  border: none;
}

.savebtn1:hover {
  background-color: #11161d;
}

.bottomDiv {
  width: 100%;
  display: flex;
  justify-content: end;
  padding: 24px;
  border-top: 1px solid #ccc;
}

.bottomDiv .savebtn {
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #FFFFFF;
  text-transform: inherit;
  background-color: #2C394B;
}

.bottomDiv .savebtn:hover {
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #FFFFFF;
  text-transform: inherit;
  background-color: #171f2a;
}

.checkboxes {
  display: flex;
  align-items: center;
  justify-content: center;
}

.confirmationBox {
  padding: 36px 36px 36px 36px;
}

.confirmationTitle {
  font-size: 25px;
  color: #424242;
  font-weight: 600;
  margin: 0px 0px 6px;
}

/* .confirmationSubTitle {
  font-size: 14px;
  color: #424242;
  margin: 15px 0px 0px;
  font-weight: 500;
} */

.confirmOwner {
  font-size: 17px;
  color: #424242;
  margin: 15px 0px 6px;
}

.confirmOwnerDiv {
  display: flex;
}

.confirmOwnerInner {
  width: 33.33%;
  padding-left: 5px;
  padding-right: 8px;
}

.confirmTitle {
  font-size: 14px;
  margin: 0px 0px 6.5px;
  color: #424242;
  font-weight: 600;
}

.confirmSubTitle {
  font-size: 13px;
  margin: 0px 0px 6.5px;
  color: #424242;
}

.confirmInfo {
  margin-bottom: 20px;
}

.activetag {
  background-color: #2C394B;
  padding: 2px 7px;
  color: white;
  font-size: 11px;
  border-radius: 5px;
}

.inactivetag {
  background-color: #F64E60;
  padding: 2px 7px;
  color: white;
  font-size: 11px;
  border-radius: 5px;
}

.rListAction {
  width: fit-content;
  height: fit-content;
  border-radius: 5px;
  background-color: #F3F6F9;
  padding: 0px 5px 0px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 0 0 0;
}

.actionDiv {
  display: flex;
  gap: 10px;
}

.actionBtns {
  border: none;
  color: #F3F6F9; 
}

.rListTable {
  display: flex;
  justify-content: flex-start;
  padding: 16px 16px 0 16px;
  align-items: center;
}

.rListTitle {
  color: #424242;
  font-size: 16.25px;
  font-family: 'Open Sans', sans-serif;
}

.rListTitle2 {
  font-family: 'Open Sans', sans-serif;
  font-size: 17px;
  font-weight: 500;
}

.rListHeader {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  margin: 0 10px 0 30px;
}

.rListSearch {
  display: flex;
  padding: 5px;
  background-color: #F3F6F9;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
}

.rListSearch1 {
  font-family: 'Open Sans', sans-serif;
  width: 200px;
  background-color: #F3F6F9;
  border: none;
  outline: none;
  padding: 5px;
  color: #424242;
}

.rListSearch2 {
  font-family: 'Open Sans', sans-serif;
  width: 120px;
  background-color: #F3F6F9;
  border: none;
  outline: none;
  padding: 5px;
  color: #424242;
}

.rListMainTable {
  width: 100%;
  height: 500px;
  padding: 10px 25px 25px 25px;
}

.sessionEnd1 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 20px;
}

.sessionEnd2 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  padding: 0 10px 0 10px;
}

.sessionEnd3 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.deleteChainPopup {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 0 30px 0 30px;
}

.deleteChainBtn {
  background-color: #ea2525;
  border-radius: 5px;
  border: none;
  outline: none;
  padding: 12px 20px 12px 20px;
  font-size: 15px;
  color: white;
  font-family: 'Open Sans', sans-serif;
}

.cancelDeleteChainBtn {
  background-color: #2C394B;
  border-radius: 5px;
  border: none;
  outline: none;
  padding: 12px 20px 12px 20px;
  font-size: 15px;
  color: white;
  font-family: 'Open Sans', sans-serif;
}

.resListTable {
  width: 100%;
  height: 500px;
  padding: 0 4px 0 0;
  margin: 30px 0 0 0;
  overflow-y: scroll;
}

.resListTable table {
  font-family: 'Open Sans', sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.resListTable th {
  position: sticky; 
  top: -1px; 
  background-color: white;
}

.resListTable td, th {
  border-top: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
.resListTable tr:last-child {
  border-bottom: 1px solid #dddddd;
}

.resListTableOuter {
  padding: 30px;
}

/*  
.yesinputBox {
  position: relative;
  width: 50%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 3px;
}

.yesinput {
  opacity: 0.011;
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.42rem;
  cursor: pointer;
}

.yeslabel {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.42rem;
  background: #1BC5BD;
  border-color: #1BC5BD;
}  */

.chainList-wrapper {
  width: 100% !important;
}

.action-btn {
  background-color: #F3F6F9;
  border-color: #F3F6F9;
  color: #2C394B;
  border-radius: 5px;
  width: 30px !important;
  height: 30px !important;
  display: flex;
  align-items: center;
  margin: 5px 0 0 0;
}

.action-btn:hover {
  background-color: #31344b;
  color: #ffffff;
}

.gridPanel .ag-theme-alpine .ag-row .action-btn svg {
  color: #2C394B;
  margin: 0 auto;
}

.gridPanel .ag-theme-alpine .ag-row .action-btn:hover svg {
  color: #ffffff;
}

.main .contentForm .gridHeading .MuiButtonBase-root.MuiButton-root.black-btn {
  color: #FFFFFF;
  background-color: #11161d;
  border-color: #11161d;
  border-radius: 5px;
  height: 38px;
  padding: 0 10px;
}

.main .contentForm .Form_root .TextArea_innerWrapper .TextArea_input {
  order: 2;
  display: block;
  width: 100%;
  padding: 9px 16px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #424242;
}

.main .contentForm .Form_root .TextArea_innerContainer .TextArea_label {
  order: 1;
  display: block;
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #424242;
  margin-bottom: 8px;
}

.checkbox-group {
  display: block;
  margin: 15px 0 0 0;
}

.checkbox-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.checkbox-group label {
  position: relative;
  cursor: pointer;
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #424242;
}

.checkbox-group label:before {
  content: "";
  /* -webkit-appearance: none; */
  background-color: transparent;
  border: 3px solid #5e5f74;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 8px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  border-radius: 2px;
}

.checkbox-group input:checked+label:after {
  content: "";
  display: block;
  position: absolute;
  top: 3px;
  left: 8px;
  width: 4px;
  height: 9px;
  border: solid #5e5f74;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
  border-radius: 2px;
}

.main .contentForm .Form_root .InputSelect.dropdownField {
  width: 23%;
}

.main .contentForm .Form_root .formRow.threeCol .Input_root {
  width: 31%;
}

.main .contentForm .Form_root .formRow.bigLbl .Input_root .Input_inputRoot .Input_label {
  font-size: 13px;
  font-weight: 600;
}

.main .contentForm .add-btn {
  color: #FFFFFF;
  background-color: #11161d;
  border-color: #11161d;
  border-radius: 5px;
  height: 38px;
  padding: 0 10px;
}

.main .contentForm .add-btn {
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #FFFFFF;
  text-transform: inherit;
  background: #FFFFFF;
  border: 1px solid #ccc;
  height: 37px;
  color: #2C394B;
  line-height: 37px;
}

.main .contentForm .add-btn:hover {
  background: #F3F6F9;
}

.main .contentForm .Form_root .formRow.add-more {
  justify-content: flex-start;
}

.main .contentForm .Form_root .formRow.add-more .add-btn {
  margin: 26px 0 0 15px;
}

.mapDiv {
  position: relative;
}

.mapPopup {
  position: absolute;
}

/*.mapWrapper div:nth-child(1) div {
  position: inherit !important;
}
.mapWrapper div:nth-child(1) div div {
  position: absolute !important;
}
*/
.RestChain-field {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.RestChain-field .InputSelect {
  width: 82%;
}

.RestChain-field .MuiButton-root {
  height: 40px !important;
}

.errorMsg {
  margin: 0 0 0 24px;
  display: flex;
  justify-content: start;
  align-items: center;
}

.errorMsg span {
  width: 18px;
  height: 18px;
  text-align: center;
  color: #ffffff;
  background: #e52416;
  border-radius: 100%;
  margin: 0 8px 0 0;
}

.errorMsg span svg {
  width: auto;
  height: auto;
  margin: 0;

}

.main .contentForm.form-container {
  box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.05);
  border: 0;
  background: #ffffff;
  width: 100%;
  border-radius: 8px;
}

.main .contentForm .Form_root .formButtonMain.btnRow {
  border: 0;
  padding: 0 36px 32px 36px;
}

.main .contentForm .Form_root .formButtonMain.btnRow .MuiButton-root {
  min-width: 200px;
  min-height: 50px !important;
}

.contactInfo {

  padding: 15px;
}

.contactInfo .contact-block {

  text-align: left;
  padding:10px  0;

}

.contactInfo .contact-block:nth-child(2) {
  border-top: solid 1px #cccccc;
  border-bottom: solid 1px #cccccc;
}

.contactInfo .contact-block h1 {
  font-size: 16px;
  font-weight: 500;
  color: #424242;
  margin: 0 0 20px 0;
}

.contactInfo .contact-block .icon span {
  border-radius: 6px;
  padding: 5px;
  width: 30px !important;
  height: 30px !important;
  margin: 15px auto;
  font-size: 16px;
  line-height: 100%;
  margin: 0 10px 0 0;
  text-decoration: none;
  display: inline-block;
}

.contactInfo .contact-block .icon.emailIcon span {
  background: #E1E9FF;

}
.contactInfo .contact-block .icon {
  margin: 0 0 10px 0;
}
.contactInfo .contact-block .icon.emailIcon {
  margin: 0 0 15px 0;
}

.contactInfo .contact-block .icon.callIcon span {
  background: #FFE2E5;
}

.contactInfo .contact-block .icon span svg {
  color: #666666;
  margin: 1px 0 0 2px;
}

.contactInfo .contact-block a {
  color: #2C394B;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.contactInfo .contact-block a:hover {
  color: #12827c;
}

.contactInfo .contact-block a:hover span {
  color: #666666;
}

.main .contentForm hr {
  margin: 0 0 25px 0;
  border-top: solid 1px #cccccc;
}

.main .contentForm h3 {
  font-size: 15px;
  font-weight: 500;
  margin: 0 0 10px 0;
}

.main .contentForm .add-more-btn {
  color: #FFFFFF;
  background-color: #2C394B;
  padding: 8px 10px;
  border: 0px none;
  border-radius: 5px;
  font-size: 12px;
  display: flex;
  align-items: center;
  margin: 0 0 15px 0;
  width: max-content;
  line-height: normal;
  height: auto;
}

.add-more-btn:hover {
  background: #11161d !important;
}

/*
.main .contentForm .Form_root .formRow.formHead {
  margin: 0 0 10px 0;
}
.main .contentForm .Form_root .formRow.formHead label {
 width: 24%;
 font-size: 14px;
 font-weight: 500;
}
.main .contentForm .Form_root .formRow.fourCol .Input_root {
  width: 24%;
}*/
.main .contentForm .Form_root .formRow.fourCol .formCol h3 {
  margin: 0 0 15px 0;
  font-size: 14px;
  font-weight: 500;
}

.main .contentForm .Form_root .formRow.fourCol .formCol {
  width: 24%;
}

.main .contentForm .Form_root .formRow.fourCol .formCol .InputSelect.dropdownField {
  width: 90%;
}

.main .contentForm .Form_root .formRow.fourCol .formCol .Input_root {
  width: 90%;
}

.main .contentForm .Form_root .formRow.threeCol .reminder-checkbox {
  width: 31%;
}

.main .contentForm .Form_root .formRow.threeCol .reminder-checkbox label {
  margin: 40px 0 0 0;
  display: flex;
  align-items: center;
}
.main .contentForm .Form_root .formRow.threeCol .reminder-checkbox input{
  width: 32px;
  height: 14px;
}

.posnameErr {
  color: red;
  margin: -22px 0 21px 6px;
}

.posnameErrDiv {
  margin: -22px 0 18px 6px;
}

.main .contentForm .Form_root .formRow .formCol label {
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #424242;
}

.grid-action-btn {
  font-size: 11px !important;
  text-transform: none !important;
  min-width: auto !important;
  background: #fff !important;
  border: 1px solid #ccc !important;
  height: 30px !important;
  margin: 0 5px 4px 0 !important;
  font-weight: 500 !important;
  color: #2c394b !important;
}

.alert-popup.react-confirm-alert-overlay {
  background: rgba(0, 0, 0, 0.4) !important;
}

.alert-popup .react-confirm-alert-body {
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.13) !important;
  padding: 20px 30px !important;
}

.alert-popup .react-confirm-alert-body h1 {
  font-family: 'Open Sans', sans-serif;
  font-size: 17px;
  font-weight: 500;
  color: #181d1f;
  text-align: center;
  margin: 0 0 25px 0;
}

.alert-popup .react-confirm-alert-button-group {
  justify-content: center;
}

.alert-popup .react-confirm-alert-button-group>button {
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #FFFFFF;
  text-transform: inherit;
  background-color: #2C394B;
}

.alert-popup .react-confirm-alert-button-group>button:hover {
  background: #11161d;
}

.main .contentForm .Form_root .serviceCharge-field {
  width: 31%;
}

.main .contentForm .Form_root .serviceCharge-field label {
  margin: 0;
  display: block;
  height: 10px;
}

.main .contentForm .Form_root .serviceCharge-field .Input_root {
  width: 100%;
}

.main .contentForm .Form_root .InputSelect.dropdownField.serviceCharger {
  width: 31%;
}

.main .contentForm .Form_root .InputSelect.dropdownField.serviceCharger label {
  margin: 0;
  height: 10px;
}

.main .contentForm h3 small {
  font-size: 12px;
  font-weight: 400;
  color: #424242;
}

.search-block-top {
  padding: 6px 5px 6px 10px;
}

.search-block-top label {
  color: #424242;
  font-weight: 400;
  margin: 0 5px 0 0;
}

.search-block-top input {
  padding: 5px 5px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: 300;
  color: #424242;
  margin: 0 5px 0 0;
}

.contactUsDetailsInner {
  width: 100%;
  padding: 20px 20px 20px 30px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.05);
  display: flex;
    justify-content: space-between;
    margin: 0 0 25px 0;
}


.contactDetailsSub {
  margin:0;
}

.contacteEmailPhone {
  display: flex;
  justify-content: left;
  align-items: center;
  margin: 10px 0 10px 0;
}

.contactIconEmail {
  background-color: #E1E9FF;
  padding: 5px 5px 3px 5px;
  border-radius: 6px;
  margin: 0;
}

.contactIconPhone {
  background-color: #FFE2E5;
  padding: 5px 5px 3px 5px;
  border-radius: 6px;
}

.contactSpan {
  margin-left: 10px;
}
.contactUsDetailsInner h4 {
  font-size: 18px !important;
  margin: 0 0 20px 0 !important;
  font-weight: 600 !important;
  margin: 0 0 20px 0;
}
.ant-layout-sider {
  z-index: 1001;
}
#divSchedule, #divScheduleDts {
  width: 680px;
}

#divSchedule .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  width: 95% !important; 
  padding: 0 10px 0 0;
}
#divScheduleDts .white-block-container {
  width: 100%;
}
#divScheduleDts .white-block-container {
  margin: 0 auto 0 auto;
}
#divSchedule.white-block-container {
  padding: 20px 30px;
}
#divSchedule .react-datepicker-popper .react-datepicker__time-list {
  height: 330px !important;
}


.viewRest {
  min-width: 135px !important;
}
.editChain{
  min-width: 85px !important;
}
.deleteChain {
  min-width: 100px !important;
}

.menuInput {
  order: 2;
  display: block;
  width: 100%;
  padding: 9px 16px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #424242;
}

.uploadmenuDiv {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
  margin: 40px 0 0 0;
}

.uploadmenuDiv label {
  display: flex;
  justify-content: start;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 7px;
  width: 50%;
}

.createMenuDiv {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
}

.createMenuDiv span {
  margin: 0 0 0 10px;
  font-weight: 600px;
}

.uploadmenuDiv {
  margin: 30px 0 10px 0;
}
.ph-num-field.phNumber .react-tel-input .form-control {
  
  width: 100% !important;
}
.ph-num-field.phNumber {
  margin: 0 0 24px 0;
}
.ph-num-field.phNumber .react-tel-input .form-control {
  width: 100% !important;
  height: 37px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #424242;
}
.ph-num-field.phNumber label {
display: block;
font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  font-weight: 600;
  color: #424242;
  margin-bottom: 8px;
}
.ph-num-field.phNumber .react-tel-input .selected-flag {
border-radius: 6px 0 0 6px;
}
.main .contentForm .ph-num-field label  {
margin-bottom: 12px;
}

.main .contentForm .Form_root .formRow.halfField .Input_root {
width: 50%;
}
.main .contentForm .ph-num-field.halfField {
width: 50%;
}
.ph-num-field .react-tel-input .form-control {
width: 100%;
}



.benefits-pos-block .benefits-pos-text {}	
.benefits-pos-block .benefits-pos-text h4 { font-size: 32px; font-weight: 600; color: #FFFFFF; margin: 0 0 24px 0;}

.benefits-pos-block .benefits-pos-text .accordion { border: 0px none; border-radius: 0px;}
.benefits-pos-block .benefits-pos-text .accordion .accordion-item { border: 0px none; border-radius: 0px; 
	background:#F7F7F7; margin:0 0 12px 0;
	-webkit-border-radius: 10px; -moz-border-radius: 10px; -ms-border-radius: 10px; border-radius: 10px;}
	
.benefits-pos-block .benefits-pos-text .accordion .accordion-item .accordion-button { 
	font-size: 18px; font-weight: 600; padding: 12px 16px; color:#212834; background: transparent;
	-webkit-border-radius: 10px; -moz-border-radius: 10px; -ms-border-radius: 10px; border-radius: 10px;}	
.benefits-pos-block .benefits-pos-text .accordion .accordion-item .accordion-button:focus { outline:0; box-shadow: none;}
.benefits-pos-block .benefits-pos-text .accordion .accordion-item .accordion-button::after { content: "\f055";
	font-family: 'Line Awesome Free'; font-weight: 900; font-size: 26px; width:28px; height:28px;
	background-image: none; transition: none;}
.benefits-pos-block .benefits-pos-text .accordion .accordion-item .accordion-button:not(.collapsed)::after { 
	content: "\f056"; transform: none;}
	
.benefits-pos-block .benefits-pos-text .accordion .accordion-item .accordion-body p { margin:0; text-align: left; font-size: 16px;}
.benefits-pos-block .benefits-pos-text .accordion .accordion-item .accordion-button:not(.collapsed) { 
	background:transparent; box-shadow: none;}

.benefits-pos-block .benefits-pos-text .accordion .accordion-item .accordion-body { padding: 0 16px 12px 16px;}



/* @media only screen and (min-width:480px) and (max-width:767px) {} */

@media only screen and (min-width: 280px) and (max-width: 800px) {

  .login-screen-main { flex-wrap: wrap; /*display: block; padding: -30px; */ }
  .img-fluid { max-width: 60%; }
  .login-screen-main .divLeft .login-bg-img { max-width: 100%; margin: 0px 0px 20px 0px; }
  .login-screen-main .divLeft .divLefttInner h1 { font-size: 30px; }
  .login-screen-main .divLeft .divLefttInner p { width: 70%; }
  .login-screen-main .divRight { max-width: 100%; padding: 20px; }
  .login-screen-main .divRight .divRightInner { width: 100%; padding: 20px; background: #FFFFFF; border-radius: 20px; }
  .login-screen-main .divRight .divRightInner .divSignIn { background: #FFFFFF; border-radius: 20px; }
  .login-screen-main .divRight .divRightInner .login-logo-img { margin-top: 18px; margin-bottom: 20px; }
  .login-screen-main .divRight .divRightInner .login-head {margin-bottom: 25px;}
  .login-screen-main .divRight .divRightInner .login-btns { display: block; text-align: center; }
  .login-screen-main .divRight .divRightInner .login-btns .MuiButtonBase-root.MuiButton-root { font-family: 'Open Sans', sans-serif; font-size: 13px;
    width: 100%; font-weight: 400; color: #FFFFFF; text-transform: inherit; background-color: #2C394B; padding: 10px 0px; margin-bottom: 10px; }

  .login-screen-main .login-bottom-menus { display: block; text-align: center; margin: 40px 0 0 0; }
  .resellerMain1 { display: none; }
  .resellerMain2 { display: block; }
  #divSchedule .white-block-container { padding: 20px 30px; }
}

@media only screen and (min-width: 800px) and (max-width: 1024px) {
  .login-screen-main .divRight { display: flex;  justify-content: center;  align-items: center; }
  .login-screen-main .divLeft { max-width: 45%; }
  .login-screen-main .divRight .divRightInner { width: 100%; padding: 20px; background: #FFFFFF; border-radius: 20px; }
  .login-screen-main .divRight .divRightInner .login-btns { display: block; text-align: center; }
  .login-screen-main .divRight .divRightInner .login-btns .MuiButtonBase-root.MuiButton-root { font-family: 'Open Sans', sans-serif; font-size: 13px;
    width: 100%; font-weight: 400; color: #FFFFFF; text-transform: inherit; background-color: #2C394B; padding: 10px 0px; margin-bottom: 10px; }

  .login-screen-main .login-bottom-menus { display: block; text-align: center; margin: 40px 0 0 0; }
  .Menubar { width: 100px; }
  .MenubarLogo img { max-height: 19px; }
  .sidebarInner { left: 100px; width: 200px; }
  .appMain .RightMenu { display: flex; justify-content: center; align-items: center; /* padding-left: 240px; */ }
  .main .contentForm { width: 600px; margin: 25px auto; background: #FFFFFF; border-radius: 8px; }
  
  .sidebar { padding-left: 0px; }

  .contactUsDetailsInner { flex-direction: column; }
  .contactDetailsSub h4 { margin:15px 0 20px 0 !important; }
  .paymentmethodslist { flex-wrap: wrap; }
  .choosepayment { width: calc(50% - 8px); margin: 0 0 8px 0; }
  .openinghrsBox .openDaysBox { flex-wrap: wrap; justify-content: space-between; }
  .openinghrsBox .openDaysBox label { width: 50%; }
  .ant-modal { width: 375px; left: 130px;}
  .main .contentForm .Form_root .formRow.halfField .Input_root {
    width: 50%;
  }
  .main .contentForm .ph-num-field.halfField {
    width: 50%;
  }
  .ph-num-field .react-tel-input .form-control {
    width: 100%;
  }
}


.ph-num-field .react-tel-input .form-control {
  height: 37px;
}

.main .contentForm.receive-ordersForm { width: 850px; }

.ph-num-field label { display: block; font-family: 'Open Sans', sans-serif; font-size: 13px; font-weight: 400; color: #424242; margin-bottom: 8px; }
.ph-num-field .react-tel-input .form-control { padding: 9px 10px 9px 45px; border: 1px solid #ccc; border-radius: 6px; font-family: 'Open Sans', sans-serif;
  font-size: 13px; font-weight: 400; color: #424242; }

.grid-blue-btn { background: #009ef7 !important; color:#ffffff !important; border-color: #009ef7 !important; font-weight: 600!important; 
  padding: 7px; border-radius: 5px;  box-shadow: none !important; letter-spacing: 0; text-transform: uppercase !important; height: 28px !important; }
.grid-dark-btn { background: #2C394B!important; color:#ffffff !important; border-color: #2C394B !important; font-weight: 600!important;
  padding: 7px; border-radius: 5px; box-shadow: none !important; letter-spacing: 0; text-transform: uppercase !important; height: 28px !important; }
.grid-red-btn { background: #7c7c7c!important; color:#ffffff !important; border-color: #7c7c7c !important; font-weight: 600!important;
  padding: 7px; border-radius: 5px; box-shadow: none !important; letter-spacing: 0; text-transform: uppercase !important; height: 28px !important; }
#divCommision .sidebarInner { display: none; }
#divCommision .RightMenu { padding-left: 0; }
.coming-soon { background: url(/src/images/coming-soon-img.png) no-repeat center center; height: 600px; background-size: 40%; }


:where(.css-dev-only-do-not-override-1uweeqc).ant-btn.ant-btn-icon-only { width: 40px; height: 40px; padding: 0px; border-radius: 0px; }
:where(.css-dev-only-do-not-override-1uweeqc).ant-btn.ant-btn-icon-only .anticon { font-size: 22px;}

.leftpanellogo { text-align: center; padding: 6px 0;}
.leftpanellogo .smallLogo { max-height: 48px;}
.leftpanellogo .bigLogo { max-height: 48px;}

.ant-layout-sider { position: fixed !important; top: 0; bottom: 0; }
.scrollDrawer2 { overflow: auto; height: 80vh; margin: 0 8px 0 0; }

/* Scrollbar Styling */
::-webkit-scrollbar { width: 6px; }
 
::-webkit-scrollbar-track { background-color: #ebebeb; -webkit-border-radius: 10px; border-radius: 6px; }
 
::-webkit-scrollbar-thumb { -webkit-border-radius: 10px; border-radius: 6px; background: #6d6d6d; }
::-webkit-scrollbar { width: 6px; height: 6px; }
::-webkit-scrollbar-track { border-radius: 10px; }
::-webkit-scrollbar-thumb { border-radius: 10px; }

.headerMobile { position: sticky; top: 0; background-color: white; padding: 7px; z-index: 1000; display: flex; gap: 15px;
/* box-shadow: 0px 5px 5px rgba(163, 163, 163, 0.25); */ border-bottom: 1px solid rgba(229, 231, 235, 1); }

#divSchedule, #divScheduleDts { margin: 30px auto 20px auto; }
#divSchedule .react-datepicker__tab-loop { margin: 0 -13px 0; }
.guests-dtls .radio-list span { margin:0 0 0 8px; text-align: left; }
.guests-dtls .radio-list.phonecall span { margin: 0 0 0 5px; }
.guests-dtls .radio-list.zoom .img-fluid { max-width: 22px !important; }
.guests-dtls .radio-list.googlemeet .img-fluid { max-width: 20px !important; }
.guests-dtls .radio-list.phonecall .img-fluid { max-width: 20px !important; }

.main .contentForm { padding: 0 5px 5px 0;}
.gridBtns { display: flex; justify-content: flex-start; }


/*----------------------------------
    MEDIUM LAYOUT: 1280px
-----------------------------------*/


@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .rListTable .rListHeader .rListSearch1 { display: flex; width: 100%; justify-content: space-between;}
  .rListTable .rListHeader .rListSearch2 { display: flex; width: 100%; justify-content: space-between;}
  .rListTable { flex-direction: column; align-items: self-start; }
  .rListHeader { margin: 0 0 10px 0; align-items: flex-start; width: 100%; }
  .rListSearch { width: 100%; justify-content: flex-start; }
  .search-block-top { padding: 6px 14px 6px 14px; }
  .customeSearch { width: 100%; }
  .search-block-top .react-datepicker-wrapper { width: 35%; margin: 0 15px 0 0; }
  .search-block-top .react-datepicker-wrapper:last-child { width:40%; margin: 0 0 0 0; }
  .search-block-top input { padding: 8px 5px; width: 100%; }
  .paymentmethodslist { flex-wrap: wrap; }
  .choosepayment { width: calc(50% - 8px); margin: 0 0 8px 0; }
}



/*----------------------------------
    TABLET LAYOUT: 768px
------------------------------------*/

@media (min-width: 768px) and (max-width: 991px) {

  .rListTable {display: block;}
  .rListTable .rListTitle2 { margin: 0 0 16px 0;}
  .rListTable .rListHeader { display: block;}
  .rListTable .rListHeader .rListSearch { margin: 0 0 10px 0; justify-content: space-between;}
  .rListTable .rListHeader .rListSearch1 { display: flex; width: 100%; justify-content: space-between;}
  .rListTable .rListHeader .rListSearch2 { display: flex; width: 100%; justify-content: space-between;}

  .main { padding: 0 24px;}
  .main .contentForm { width: 100%; margin: 16px auto;}
  .ant-drawer .ant-drawer-body { padding: 0;}
  .ant-drawer .ant-drawer-body .ant-layout-sider { max-width: 256px!important; min-width: 256px!important; width: 256px!important;}
  .scrollDrawer { overflow-y: scroll; height: 520px; margin: 0 6px 0 0; }

  /* .rListTable .rListHeader .rListSearch1 { display: flex; width: 100%; justify-content: space-between;}
  .rListTable .rListHeader .rListSearch2 { display: flex; width: 100%; justify-content: space-between;} */
  .contactUsDetailsInner { flex-direction: column; }
  .contactDetailsSub h4 { margin:15px 0 20px 0 !important }
  #divSchedule, #divScheduleDts { width: 100%; }
  .react-datepicker__month-container { }
  .react-datepicker__month { margin: 0; }
  .react-datepicker__month-container { width: 50%; }

  #divSchedule h1, #divScheduleDts h1 { font-size: 20px; }
  #divSchedule h2 { font-size: 18px; }
  #divSchedule .react-datepicker-popper .react-datepicker__navigation--next { right: 56%; }
  #divSchedule .react-datepicker-popper .react-datepicker__navigation--previous { left: 30px; }
  #divSchedule .time-zone .time-zone-inner { text-align: left; align-items: flex-start; }
  #divSchedule .time-zone .time-zone-inner .dropdown-block { display: inline-block; }
  #divSchedule .time-zone { margin: 15px 0 0 0; }
  #divSchedule .time-zone h2 { margin: 0 0 5px 0; }
  #divSchedule .next-btn { padding: 5px 8px; }
  #divSchedule .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list { width: 90%; padding: 0 8px 0 0; }
  #divSchedule, #divScheduleDts { width: 100%; }
  #divSchedule .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list { width: 95%; margin: 0 0 0 10px; }
  #divSchedule .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list { width: 89% !important; padding: 0 10px 0 0 !important; }
  #divScheduleDts p { margin: 10px 0 0 0; }
  #divScheduleDts h1 { margin: 25px 0 20px 0; }
  #divSchedule, #divScheduleDts { margin: 10px auto 20px auto; }
  #divSchedule .white-block-container, #divScheduleDts .white-block-container { margin: 0 auto 0 auto; }
  .white-block-container .form-block { width: 85%; }
  #divSignIn, #divSignUp{ /* width: 80%; margin: 0 auto; */ }

  .search-block-top .react-datepicker-wrapper { width: 38%; }
  .rListSearch.search-block-top {  padding: 10px 14px; justify-content: flex-start; }
  .rListSearch.search-block-top label { margin: 0 10px 5px 0; font-weight: 400; }
  .rListSearch.search-block-top input { padding: 10px 5px; }
  .rListHeader { margin: 0; }
  .rListSearch.search-block-top .react-datepicker-wrapper input{ width: 95%; }
  .rListSearch.search-block-top .react-datepicker-wrapper:last-child input{ width: 100%; }
  .main .contentForm .Form_root .formRow.threeCol .reminder-checkbox label { margin: 40px 0 0 0; display: flex; align-items: center; }
  .main .contentForm .Form_root .formRow.threeCol .reminder-checkbox input { width: 30px; height: 16px; }
  .ph-num-field.phNumber .react-tel-input .form-control {
    width: 100% !important;
  }
  .main .contentForm .Form_root .formRow.halfField .Input_root {
    width: 50%;
  }
  .main .contentForm .ph-num-field.halfField {
    width: 50%;
  }
  .ph-num-field .react-tel-input .form-control {
    width: 100%;
  }

  .login-screen-main .divLeft, .login-screen-main .divRight { width: 100%;}

  
.login-screen-main .divRight { order: 1; padding: 36px 24px;}
.login-screen-main .divLeft { order: 2; padding: 0 24px 24px 24px;}

.benefits-pos-block .benefits-pos-text h4 { font-size: 26px;}
.benefits-pos-block .benefits-pos-text .accordion .accordion-item:last-child { margin: 0;} 
.login-screen-main .divRight .divRightInner .login-logo-img img { max-width: 220px;}
.login-screen-main .divRight .divRightInner .login-head h2 { font-size: 26px;}

}

@media only screen and (max-width: 912px) {	
  .search-block-top .react-datepicker-wrapper { width: 35%; }
  .react-datepicker__input-container { width: 100%; }
}

@media only screen and (width: 820px) and (height: 1180px) {
  #divSchedule .react-datepicker-popper .react-datepicker__time-container { width: 160px; }
  #divSchedule .react-datepicker-popper .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box { width: 140px; }
  #divSchedule .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list { width: 90% !important; padding: 0 4px 0 0 !important; }
  #divSchedule .react-datepicker-popper .react-datepicker__day-name, .react-datepicker__day, #divSchedule .react-datepicker-popper .react-datepicker__time-name { width: 40px; }
  #divSchedule .react-datepicker-popper .react-datepicker__day, #divSchedule .react-datepicker-popper .react-datepicker__month-text, #divSchedule .react-datepicker-popper .react-datepicker__quarter-text, #divSchedule .react-datepicker-popper .react-datepicker__year-text {
    width: 40px; height: 40px; line-height: 40px; }

  #divSchedule .react-datepicker-popper .react-datepicker__current-month { margin: 0 0 20px 80px; }
  #divSchedule .react-datepicker-popper .react-datepicker__navigation--next { right: 44%; }
  .white-block-container .form-block { width: 85%; }

  #divSchedule, #divScheduleDts { margin: 10px auto 20px auto; }
  #divSchedule .white-block-container, #divScheduleDts .white-block-container { margin: 0 auto 0 auto; }
  .login-screen-main .divLeft .divLefttInner p { width: 100%; }
  #divSignIn, #divSignUp { width: 100%; margin: 0 auto; }
  
  #divSchedule .react-datepicker-popper .react-datepicker__time-list { height: 285px !important; }
  .rListSearch.search-block-top { flex-direction: column; align-items: flex-start; padding: 10px 15px; }
  .rListSearch.search-block-top .react-datepicker-wrapper { width: 100%; margin: 0 0 8px 0; }
  .rListSearch.search-block-top label { margin: 0 0 5px 0; font-weight: 400; }
  .rListSearch.search-block-top input { width: 100%; padding: 10px 5px; }
  .rListSearch.search-block-top .react-datepicker-wrapper input { width: 100%; }
  .ph-num-field.phNumber .react-tel-input .form-control {
    width: 100% !important;
  }
}



/*----------------------------------
    MOBILE LAYOUT: 320px
-----------------------------------*/

@media only screen and (max-width: 767px) {	

  .main { padding: 0 16px;}
  .main .contentForm { width: 100%; margin: 16px auto;}

  .main .contentForm.chainList-wrapper .gridHeading { display: block;}
  .main .contentForm.chainList-wrapper .gridHeading h2 { margin: 0 0 12px 0;}

  .ant-drawer .ant-drawer-body { padding: 0;}
  .ant-drawer .ant-drawer-body .ant-layout-sider { max-width: 256px!important;
    min-width: 256px!important; width: 256px!important;}

  .location-block { display: block; padding: 16px; margin: 0;}
  .location-block .radio-list { width: 100%; margin: 0; padding: 16px;}
  .location-block .radio-list:first-child { margin-bottom: 16px!important;}
  .location-block .radio-list:first-child, .location-block .radio-list:nth-child(2) { margin: 0;}
  .main .contentForm .Form_root .formButtonMain { padding: 16px;}

  .main .contentForm .Form_root .Input_root { margin: 0 0 26px 0;}
  .main .contentForm .Form_root .InputSelect { margin: 0 0 16px 0;}

  .main .contentForm .formHeading { padding: 0 16px;}
  .main .contentForm .Form_root .Form_root_Inner { padding: 16px;}

  .main .contentForm .Form_root .formRow { display: block;}
  .main .contentForm .Form_root .formRow .Input_root { width: 100%;}

  .RestChain-field { display: block; margin: 0 0 16px 0;}
  .RestChain-field .InputSelect { width: 100%; margin: 0 0 16px 0 !important;}
  .RestChain-field .MuiButton-root { height: 24px !important; padding: 0;}

  .main .contentForm .Form_root .InputOnlineOrdering .Input_root .Input_inputRoot { display: grid;}
  .main .contentForm .Form_root .InputOnlineOrdering .Input_root .Input_inputRoot .Input_label { border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;}
  .main .contentForm .Form_root .InputOnlineOrdering .Input_root .Input_inputRoot .Input_input { width: 100%;
    border-top-left-radius: 6px; border-bottom-left-radius: 6px; display: block; margin: 12px 0 0 0;} 

  .main .contentForm .Form_root .Input_root .Input_inputRoot .Input_label { margin-bottom: 12px;}
  .main .contentForm .Form_root .InputSelect label { margin-bottom: 12px;}

  .activeServicesBox { margin: 16px;}
  .activeServicesBox .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root { padding-left: 0;}
  .mainService { padding-left: 16px; padding-right: 16px; padding-bottom: 24px;}
  .delayinput { margin-left: 0;}
  .daysoptions { flex-wrap: wrap;}
  .daysoptions label { width: 50%;}
  .daysoptions label .daysbox .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root { padding-left: 0;}
  .paymentmethodslist { flex-wrap: wrap;} 
  .choosepayment { width: calc(50% - 8px); margin: 0 0 8px 0;}

  .bottomDiv { padding: 16px;}

  .main .contentForm .Form_root .serviceCharge-field, 
  .main .contentForm .Form_root .InputSelect.dropdownField.serviceCharger { width: 100%;} 

  .main .contentForm .Form_root .formRow.threeCol .Input_root { width: 100%;}

  .main .contentForm .Form_root .formButtonMain.btnRow { padding: 16px;}
  .main .contentForm .Form_root .formButtonMain.btnRow .MuiButton-root { min-width: 100%;}

  .openinghrsBox { padding: 16px;}

  .openinghrsBox .copymonday .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root { padding-left: 0;}

  .openinghrsBox .daysDropdown { width: 100%;}
  .openinghrsBox .openDaysBox { flex-wrap: wrap; justify-content: space-between;}
  .openinghrsBox .openDaysBox label { width: 50%;}
  .openinghrsBox .openDaysBox .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root { padding-left: 0;}

  .chooseTimeBox { display: block; margin: 0 0 16px 0; padding: 0;}
  .chooseTimeBox label { margin: 0 0 12px 0; display: block;}
  .chooseTimeBox div:last-child label { margin: 12px 0 12px 0;}
  .chooseTimeBox br { display: none;}
  .chooseTimeBox .timepicker { width: 100%;}

  .main .contentForm .contentFormBody { padding: 16px;}

  .onlinepaymentInner { padding: 16px;}

  .openingHoursBox { display: block;}
  .main .contentForm .openingHoursBox h3 { margin: 0 0 16px 0;}

  .main .contentForm.receive-ordersForm .Form_root .formRow { display: flex; width: 100%;}

  .rListTable {display: block;}
  .rListTable .rListTitle2 { margin: 0 0 16px 0;}
  .rListTable .rListHeader { display: block;}
  .rListTable .rListHeader .rListSearch { margin: 0 0 10px 0; justify-content: space-between;}
  .rListTable .rListHeader .rListSearch1 { display: flex; width: 100%; justify-content: space-between;}
  .rListTable .rListHeader .rListSearch2 { display: flex; width: 100%; justify-content: space-between;}

  .headerMobile { position: sticky; top: 0; background-color: white; padding: 7px; z-index: 1000; border-bottom: 1px solid rgba(229, 231, 235, 1); }

  .scrollDrawer { overflow-y: scroll; height: 520px; margin: 0 6px 0 0; }

  .scheduletable { width: 100%; border: 1px solid grey; border-collapse: collapse; font-family: 'Open Sans', sans-serif; }
  .tableDiv { width: 100%; overflow-x: scroll; }

  .sessionEnd2 { justify-content: center; align-items: center; text-align: center; }

  .main .contentForm.receive-ordersForm { width: 100%; }
  .main .contentForm .Form_root .formRow.fourCol .formCol { margin: 0 15px 0 0; }

  .pageBtmScroll { overflow-x:auto; }
  .main .contentForm.receive-ordersForm .Form_root_Inner { width: 100%; }
  .main .contentForm .Form_root .formRow.threeCol.contectDtls { justify-content: flex-start; display: block; }
  .main .contentForm .Form_root .formRow.threeCol.contectDtls .Input_root { width: 100%; margin: 0 20px 16px 0; }

  .contactUsDetailsInner { width: 100%; padding: 20px 20px 20px 30px; background-color: #ffffff; border-radius: 10px; 
    box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.05); margin-bottom: 30px; } 

  .contactUsDetailsInner { flex-direction: column; }
  .contactUsDetailsInner .contactDetailsSub h4 { margin: 10px 0 20px 0 !important; }

  .white-block-container { width: 100%; }

  .react-datepicker__month-container { float: none !important; }
  .react-datepicker__month {  margin: 0; }
  #divSchedule .react-datepicker-popper .react-datepicker__day-name, .react-datepicker__day, #divSchedule .react-datepicker-popper .react-datepicker__time-name { width: 40px; }
  #divSchedule .react-datepicker-popper .react-datepicker__day, #divSchedule .react-datepicker-popper .react-datepicker__month-text, #divSchedule .react-datepicker-popper .react-datepicker__quarter-text, #divSchedule .react-datepicker-popper .react-datepicker__year-text {
    width: 40px; height: 40px; line-height: 40px; }
  #divSchedule h1, #divScheduleDts h1 { font-size: 20px; }
  #divSchedule h2 { font-size: 18px; }
  #divSchedule .react-datepicker-popper .react-datepicker__navigation--next { right: 20px; }
  #divSchedule .react-datepicker-popper .react-datepicker__navigation--previous { left: 30px; }
  #divSchedule .react-datepicker-popper .react-datepicker__time-container { float: none; margin: 0 auto; }
  #divSchedule .react-datepicker-popper .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box { width: 90%; }
  #divSchedule .time-zone .time-zone-inner { text-align: left; align-items: flex-start; }
  #divSchedule .time-zone { margin: 15px 0 0 0; }
  #divSchedule .time-zone h2 { margin: 0 0 5px 0; }
  #divSchedule .next-btn { padding: 5px 8px; }
  #divSchedule .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list { width: 90%; padding: 0 8px 0 0; }
  #divSchedule, #divScheduleDts { width: 100%; }
  #divSchedule .time-zone .css-b62m3t-container { /*width: 200px;*/ }
  #divSchedule .time-zone .time-zone-inner .dropdown-block { width: 77%; }
  
  .scheduleDtls { flex-direction: column; margin: 0; }
  .scheduleDtls div { margin: 0 0 10px 0; }
  .scheduleDtls p { margin: 10px 0 0 0; }

  #divSchedule, #divScheduleDts { margin: 16px auto 20px auto; }
  #divScheduleDts { margin: 10px auto 20px auto; }
  #divScheduleDts .white-block-container { margin: 0; }
  #divScheduleDts h1 { margin: 30px  0 15px 0; }
  #divScheduleDts p { margin: 10px 0 0 0; }

  .scheduleDtls div { align-items: baseline; display: block; }
  .white-block-container .lineDiv { margin: 15px 0; }
  .white-block-container .form-block { width: 100%; }

  #divScheduleDts { padding: 0; }
  #divSchedule, #divScheduleDts { margin: 10px auto 20px auto; }
  #divSchedule .white-block-container, #divScheduleDts .white-block-container { margin: 0 auto 0 auto; }

  .login-screen-main .divLeft .divLefttInner p { width: 100%; }
  
  #divSchedule.white-block-container { padding: 20px 20px; }
  .rListSearch.search-block-top { flex-direction: column; align-items: flex-start; padding: 10px 15px; }
  .rListSearch.search-block-top .react-datepicker-wrapper { width: 100%; margin: 0 0 8px 0; }
  .rListSearch.search-block-top label { margin: 0 0 5px 0; font-weight: 400; }
  .rListSearch.search-block-top input { width: 100%; padding: 10px 5px; }
  .rListHeader { margin: 0; }

  #divSchedule .time-zone .dropdown-block select, #divSchedule .time-zone .dropdown-block { width: 100%; }
  #divSchedule .time-zone .dropdown-block select { margin: 0 10px 0 10px; }
  .main .contentForm .Form_root .formRow.threeCol .reminder-checkbox label { margin: 34px 0 0 0; display: flex; align-items: center; }
  .main .contentForm .Form_root .formRow.threeCol .reminder-checkbox { width: 100%;}
  .main .contentForm .Form_root .formRow.threeCol .reminder-checkbox input { width: 25px; height: 15px;}

  .confirmOwnerDiv { display: block; }
  .confirmOwnerInner{width: 100%; padding: 0 0 10px 0;}
  .confirmInfo .mobilebr{display: none;}
  .resListTableOuter {padding: 5px;}
  .chooseTimeBox div { width: 100%; }
  .chooseTimeBox .timepicker { width: 100%; }
.ph-num-field {
  margin: 0 0 26px 0;
}
.ph-num-field .react-tel-input .form-control {
  width: 100%;
}
.main .contentForm .Form_root .formRow.halfField .Input_root {
  width: 100%;
  }
  .main .contentForm .ph-num-field.halfField {
    width: 100%;
  }
  .ph-num-field .react-tel-input .form-control {
    width: 100%;
}

.login-screen-main .divLeft, .login-screen-main .divRight { width: 100%;}

.login-screen-main .divRight { order: 1; padding: 36px 24px;}
.login-screen-main .divLeft { order: 2; padding: 0 24px 24px 24px;}

.benefits-pos-block .benefits-pos-text h4 { font-size: 26px;}
.benefits-pos-block .benefits-pos-text .accordion .accordion-item:last-child { margin: 0;} 
.login-screen-main .divRight .divRightInner .login-logo-img img { max-width: 220px;}
.login-screen-main .divRight .divRightInner .login-head h2 { font-size: 26px;}

}
/* .phonenocodeLogin {
  margin: 0 0 24px 0;
}
.phonenocodeLogin label {
  display: block;
  font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    font-weight: 600;
    color: #424242;
    margin-bottom: 8px;
}
.phonenocodeLogin .phFields {
  width: 100%;
    padding: 9px 10px 9px 0;
    border: 1px solid #ccc;
    border-radius: 6px;
    display: flex;
}
.phonenocodeLogin .phFields input {
  border: 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #424242;
  background: transparent;
}
.phonenocodeLogin .phFields input:focus {
  outline: 0;
}

.phonenocodeLogin .iti__flag-container {
  background-color: #f5f5f5;
    border: 0;
    border-radius: 6px 0 0 6px;
    top: -9px;
    bottom: 0;
    height: 35px;
    bottom: 0;
    border-right: 1px solid #ccc;
}
.phonenocodeLogin .iti__tel-input {

background: transparent;
}
.phonenocodeLogin .countryCode {
  margin: 0 8px 0 0;
} */
/*.phonenocodeLogin .iti--allow-dropdown {
  width: 50px;
  padding: 0;
}*/
/* .phonenocodeLogin .iti--allow-dropdown input.iti__tel-input {
  padding-left: 100px !important; 
} */
/* .popup-phone .form-control { width: 100%; padding-left: 0px; } */

	 /*.popup-phone { display: flex; flex-direction: row; }

	 .popup-phone  > div { display: flex;}

	.popup-phone .iti {
		width: 100%;
    height: auto;
	}

	.iti input[type="tel"]{
		display: none;
		width: 100%;
		border: none;
		border-bottom: 1px solid #929292;
	}*/

/* 
  .popup-phone { display: flex; flex-direction: row;
  }

  .popup-phone  > div { display: flex;}

	.popup-phone .iti {
		width: 100%;
    height: auto;
	}

  .popup-phone .countryCode{

		padding-right: 6px;
  		padding-left: 52px;
		margin-left: 0;
		border: none;
		background:transparent;
		font-family: ui-sans-serif,Segoe UI;
		font-size: 13px;
		font-weight: 400;
		padding: 0 6px 0 50px;
    line-height: 37px;

	}

  .phoneLabel {
    display: block;
  font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    font-weight: 600;
    color: #424242;
    margin-bottom: 8px;
  }

  .popup-phone .iti--inline-dropdown .iti__dropdown-content {
    width: auto!important;
  }
  .popup-phone {
    width: 100%;
    padding: 0 10px 0 0;
    border: 1px solid #ccc;
    border-radius: 6px;
    margin: 0 0 24px 0;
    height: 37px;
  }
  .popup-phone input, .popup-phone input {
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: #424242;
    border: 0;
    border-radius: 6px;
  }
  .popup-phone input:focus {
    outline: 0;
  }
  .popup-phone .iti--allow-dropdown .iti__flag-container {
    background-color: #f5f5f5;
      border-right: 1px solid #cacaca;
      border-radius: 6px 0 0 6px;
      padding: 4px 0px;
  }
  .popup-phone .iti--allow-dropdown .iti--allow-dropdown .iti__flag-container .iti__arrow {
    display: none;
  } */
 

/*----------------------------------
    WIDE MOBILE LAYOUT: 480px
-----------------------------------*/

